export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCPNg5FVikc2-QLFIvMmr_1XI7utxySpnQ',
    authDomain: 'like-pizzas.firebaseapp.com',
    projectId: 'like-pizzas',
    storageBucket: 'like-pizzas.appspot.com',
    messagingSenderId: '1098307954730',
    appId: '1:1098307954730:web:87a251cc3369bbaf94bb53'
  },
  pix: {
    default: 'stone',
    pagseguro: 'https://secure.api.pagseguro.com/instant-payments/cob',
    stone: {
      API: 'https://api.openbank.stone.com.br/api/v1/pix',
      AUTH: 'https://accounts.openbank.stone.com.br/auth/realms/stone_bank/protocol/openid-connect/token'
    }
  }
};
